<script>
import IconColorMixin from '@/mixins/IconColorMixin';

export default defineNuxtComponent({
  mixins: [IconColorMixin],
  props: {
    iconName: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: 24,
    },
    accent: {
      type: String,
      default: 'currentColor',
    },
    darkMode: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<template>
  <svg
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 7L9.5 12L14 17"
      :stroke="accent"
      stroke-width="2"
      stroke-linecap="round"
    />
  </svg>
</template>
